<template>
  <div>
    <v-container v-if="user.public">
      <v-row class="wrapper-line-buttons">
        <v-col class="text-center" cols="12">
          <div class="tw-toggle">
            <input id="rdo-personal" type="radio" name="toggle" value="false" :checked="content_type === 0" @click="checkContentType(0)" />
            <label for="rdo-personal" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccount}" class="mx-auto">
                {{ mdiAccount }}
              </v-icon>
            </label>
            <input id="rdo-both" type="radio" name="toggle" value="-1" @click="checkContentType(-1)" :checked="content_type === -1" />
            <label for="rdo-both" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccountMultiple}" class="mx-auto">
                {{ mdiAccountMultiple }}
              </v-icon>
            </label>
            <input id="rdo-professional" type="radio" name="toggle" value="true" @click="checkContentType(1)" :checked="content_type === 1" />
            <label for="rdo-professional" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccountTie}" class="mx-auto">
                {{ mdiAccountTie }}
              </v-icon>
            </label>
            <span></span>
          </div>
        </v-col>
      </v-row>

      <!-- <v-row>
        <div class="d-flex justify-center wrapper-menu">
          <div class="inner-wrapper-menu" v-if="active">
            <ul class="list-menu clearfix" v-if="!content_type || content_type === -1">
              <li v-for="(item) in categories" :key="`system-${item.id}`">
                <v-btn @click="searchCategory(item.id, 1)">
                  {{ item.name }}
                  <v-icon v-if="item.children"
                    :class="{'alternate-icon-small': !mdiChevronDown}"
                    class="mx-auto"
                  >
                    {{ mdiChevronDown }}
                  </v-icon>
                </v-btn>
                <ul v-if="item.children">
                  <li v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                    <span @click="searchCategory(itemL1.id, 1)">
                      {{ itemL1.name }}
                      <v-icon v-if="itemL1.children"
                        :class="{'alternate-icon-small': mdiChevronDown}"
                        class="mx-auto arrow-icon"
                      >
                        {{ mdiChevronDown }}
                      </v-icon>
                    </span>
                    <ul v-if="itemL1.children">
                      <li v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`">
                        <span @click="searchCategory(itemL2.id, 1)">{{ itemL2.name }}</span></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li v-if="!content_type">
                <v-btn @click="searchCategory(-1, 1)" v-bind:class="{ 'active': categorySelected === '' }" elevation="2">All</v-btn>
              </li>
            </ul>

            <ul class="list-menu clearfix" v-if="content_type || content_type === -1">
              <li v-for="(item) in professionalCategories" :key="`system-${item.id}`">
                <v-btn @click="searchCategory(item.id, 1)">
                  {{ item.name }}
                  <v-icon v-if="item.children"
                    :class="{'alternate-icon-small': !mdiChevronDown}"
                    class="mx-auto"
                  >
                    {{ mdiChevronDown }}
                  </v-icon>
                </v-btn>
                <ul v-if="item.children">
                  <li v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                    <span @click="searchCategory(itemL1.id, 1)">
                      {{ itemL1.name }}
                      <v-icon v-if="itemL1.children"
                        :class="{'alternate-icon-small': mdiChevronDown}"
                        class="mx-auto arrow-icon"
                      >
                        {{ mdiChevronDown }}
                      </v-icon>
                    </span>
                    <ul v-if="itemL1.children">
                      <li v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`">
                        <span @click="searchCategory(itemL2.id, 1)">{{ itemL2.name }}</span></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li v-if="content_type">
                <v-btn @click="searchCategory(-1, 1)" v-bind:class="{ 'active': categorySelected === '' }" elevation="2">All</v-btn>
              </li>
            </ul>
          </div>
        </div>
      </v-row>

      <v-row>
        <v-col class="text-subtitle-1 text-center wrapper-buttons" cols="12">
          <v-switch
            inset
            class="switch-slider-view"
            v-model="slider_view"
            :label="`Slider View`"
          ></v-switch>
        </v-col>
      </v-row> -->

      <!-- MODALS -->
      <ModalDetails class="mb-6" :actions="false" v-if="modals.content" :modals="modals" @closingModal="modalClose" @openZoom="openZoom" />
      <ModalZoom class="mb-6" v-if="modals.zoom" :modals="modals" @closingModal="modalClose" />
      <!-- ###### -->

      <v-row justify="center" class="mt-10 mb-6">
        <stack
          :column-min-width="300"
          :gutter-width="15"
          :gutter-height="15"
          monitor-images-loaded
          ref="stack"
        >
           <v-container style="height: 400px;" v-if="!files.length">
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col
                class="text-subtitle-1 text-center"
                cols="12"
              >
                There are no content to show.
              </v-col>
            </v-row>
          </v-container>
          <template v-if="slider_view">
            <stack-item
              v-for="(file, i) in files"
              :key="`files-${i}`"
              style="transition: transform 300ms"
              @click.native="showModal(file);modals.file.created_at = file.created_at.split('T')[0];"
            >
              <v-card>
                <div class="caption-style">
                  <div class="flag-container" v-if="parseInt(file.categories, 10) === 1">
                    <div class="flag">Draft</div>
                  </div>
                  <v-carousel cycle v-if="file.related_files.length > 1"
                    height="100%" @change="reflow()"
                  >
                    <v-carousel-item
                      v-for="(item,i) in file.related_files"
                      :key="i"
                    >
                      <img :src="`${item}`" :class="`rotate_images__position_${file.rotate_position[i]}`" />
                    </v-carousel-item>
                  </v-carousel>
                  <img v-else :src="`${file.related_files}`" :class="`rotate_images__position_${file.rotate_position[0]}`" />
                  <div class="caption" :data-categories="file.categories">
                    <div class="blur"></div>
                    <div class="caption-text">
                      <h1>{{file.name }}</h1>
                      <p v-html="subString(file.alternative_text)"></p>
                    </div>
                  </div>
                </div>
              </v-card>
            </stack-item>
          </template>
          <template v-if="!slider_view">
            <template v-for="file in files">
              <stack-item
                v-for="(item, i_rlt) in file.related_files"
                :key="`file_${i_rlt+Math.random()}`"
                style="transition: transform 300ms"
                @click.native="showModal(file);modals.file.created_at = file.created_at.split('T')[0];"
              >
                <v-card>
                  <div class="caption-style">
                    <div class="flag-container" v-if="parseInt(file.categories, 10) === 1">
                      <div class="flag">Draft</div>
                    </div>
                    <img :src="`${item}`" :class="`rotate_images__position_${file.rotate_position[0]}`" />
                    <div class="caption" :data-categories="file.categories">
                      <div class="blur"></div>
                      <div class="caption-text">
                        <h1>{{file.name }}</h1>
                        <p v-html="subString(file.alternative_text)"></p>
                      </div>
                    </div>
                  </div>
                </v-card>
              </stack-item>
            </template>
          </template>
        </stack>
      </v-row>
      <v-pagination
        v-model="pagination"
        :length="qtdPages"
      ></v-pagination>
    </v-container>
    <!-- <v-container style="height: 400px;" v-if="!user.public">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          The user is not public.
        </v-col>
      </v-row>
    </v-container> -->
  </div>
</template>

<script>
import axios from "axios";
import { Auth } from 'aws-amplify';
import { Stack, StackItem } from "vue-stack-grid";
import { mdiChevronDown, mdiCloudUpload, mdiAccountTie, mdiAccount, mdiAccountMultiple } from '@mdi/js';

import ModalUpload from '@/components/modals/ModalUpload.vue';
import ModalDetails from '@/components/modals/ModalDetails.vue';
import ModalEdit from '@/components/modals/ModalEdit.vue';
import ModalZoom from '@/components/modals/ModalZoom.vue';
import Agreements from '@/components/Agreements.vue';

export default {
  name: "Grid",
  directives: {
  },
  components: {
    ModalUpload,
    ModalEdit,
    ModalDetails,
    ModalZoom,
    Agreements,
    Stack,
    StackItem,
  },
  data: () => ({
    /* Icons */
    mdiChevronDown,
    mdiCloudUpload,
    mdiAccountTie,
    mdiAccount,
    mdiAccountMultiple,
    /* ---- */
    menus: true,
    slider_view: true,
    /* Categories */
    userItems: ['User Category 1', 'User Category 2', 'User Category 3'],
    model: [],
    /* --- */
    user: {
      id: 0,
      public: false
    },
    message: '',
    active: 1,
    logged: true,
    pagination: 1,
    qtdPages: 1,
    limitItems: 30,
    images: [],
    orientation: 'vertical',
    modals: {
      content: false,
      edit: false,
      upload: false,
      zoom: false,
      title: '',
      description: '',
      file: {
        created_at: '',
        url: '',
        name: '',
      },
    },
    endpoint: process.env.VUE_APP_ROOT_API,
    fileUploaded: null,
    categories: [],
    public_categories: [],
    active_menu_categories: [],
    professionalCategories: [],
    file: {
      url: '',
      related_files: [],
    },
    files: [],
    categorySelected: '',
    cmdCategory: [],
    loading: false,
    uploadPercentage: 0,
    resultUpload: false,
    networks: [
      { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f share-icon', color: '#1877f2' },
    ],
    tags: [],
    relatedFiles: [],
    content_type: 0,
    counterFlow: 0,
  }),
  watch: {
    pagination(newPage) {
      if(newPage) {
        this.callPagination(this.pagination);
      }
    },
  },
  mounted() {
    this.checkLogin();
    // this.signOut();
  },
  methods: {
    reflow() {
      if(this.counterFlow > this.files.length) {
        setTimeout(() => {
          this.$refs.stack.reflow();
        }, 500);
      }
      this.counterFlow++;
    },
    async signOut() {
      try {
        this.$store.commit('setLogin', false);
        await Auth.signOut();
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },
    getCatgContentType(val) {
      this.content_type = val;
      this.getCategories();
    },
    checkContentType(val) {
      this.content_type = val;
      this.searchCategory(-1, 1);
    },
    checkLogin() {
      const self = this;
      // this.getUser(this.$route.params.username);
      this.getFiles(this.$route.params.username);
    },
    getUser(username) {
      const self = this;
      console.log(this.$store.state.jwtToken);
      axios.get(`${process.env.VUE_APP_ROOT_API}/getPublicUser?username=${username}`, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(response => {
        if(response.data.content.length) {
          // if(response.data.content[0].public) {
            // localStorage.setItem('user_active', response.data.content[0].is_active);
            // localStorage.setItem('is_public', response.data.content[0].public);
            // self.active = true;
            // self.user.public = true;
            // self.logged =  true;
            // this.$store.commit('setPublic', response.data.content[0].public);
            this.searchCategory(-1, 1);
          // } else {
          //   self.user.public = false;
          //   this.$store.commit('setPublic', 0);
          // }
        } else {
          console.log('username do not exist');
        }
      });
    },
    callPagination(page) {
      this.searchCategory(this.categorySelected, page);
    },
    openZoom() {
      const self = this;
      self.modalClose(false);
      setTimeout(() => {
        self.modals.zoom = true;
      }, 200);
    },
    modalClose(search) {
      this.modals.edit = false;
      this.modals.content = false;
      this.fbConverting = false;
      this.modals.upload = false;
      this.modals.zoom = false;
      if(search) {
        this.searchCategory(-1, 1);
      }
    },
    subString(value) {
      if(value !== undefined) {
        return value.substring(0, 140) + '...';
      } else {
        return value;
      }
    },
    searchCategory(value, page) {
      this.categorySelected = value !== -1 ? value : '';
      this.files = [];
      this.pagination = page;
      this.getCategories();
    },
    getCategories() {
      const self = this;
      axios.get(
        `${self.endpoint}/getCategories`, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(response => {
        self.categories = [];
        self.public_categories = [];
        self.active_menu_categories = [];
        self.professionalCategories = [];
        self.public_categories = response.data.public_categories;
        self.active_menu_categories = response.data.active_menu_categories;
        response.data.content.forEach(value => {
          if(value.public && value.active) {
            if(value.type) {
              if (!value.content_type) {
                self.categories.push(value);
              } else {
                self.professionalCategories.push(value);
              }
            }
          }
        });
        self.tags = response.data.tags;
        this.getFiles();
      })
      .catch(() => {
        // ERROR
      });
    },
    getFiles(username) {
      const self = this;
      axios.get(
        `${self.endpoint}/getPublicItems?username=${username}&content_type=${this.content_type >= 0 ? this.content_type : ''}&public_categories=${self.public_categories}&active_menu_categories=${self.active_menu_categories}&category=${this.categorySelected}&limit=${(Math.abs((this.pagination - 1)) * this.limitItems)},${(this.limitItems)}`).then(response => {
        // self.qtdPages = response.data.pages;
        // self.qtdPages = Math.ceil(response.data.total_files / self.limitItems);
        // localStorage.setItem('last_location', response.data.content[response.data.content.length - 1].location);
        let categories_item = [];
        let is_public = 0;

        response.data.content.forEach(value => {
          let counterPublic = 0;
          // if(value.categories.indexOf(', ') !== -1) {
          //   categories_item = value.categories.split(', ');
          //   categories_item.forEach(valueT => {
          //     if(self.public_categories.includes(parseInt(valueT, 10))) {
          //       counterPublic++;
          //     }
          //   });
          //   if(counterPublic === (categories_item.length)) {
          //     is_public = 1;
          //   } else {
          //     is_public = 0;
          //   }
          // } else {
          //   if(self.public_categories.includes(parseInt(value.categories, 10))) {
          //     is_public = 1;
          //   } else {
          //     is_public = 0;
          //   }
          // }
          // if(is_public) {
          //   if(value.public) {
              // if(value.related_files) {
              //   if(value.related_files) {
              //     value.related_files = value.related_files;
              //   } else {
              //     value.related_files = [];
              //   }
              // }
              // if(value.rotate_position) {
              //   value.rotate_position = value.rotate_position.split(', ');
              // } else {
              //   value.rotate_position = [];
              // }
              self.files.push(value);
          //   }
          // }
        });
      })
      .catch(() => { /* ERROR */ });
    },
    showModal(file) {
      this.$set(this.modals, 'file', file);
      this.fbcounter = 0;
      this.facebookImgUrl = '';
      this.modals.content = true;
    }
  }
};
</script>

<style lang="scss">
.vsg-stack-item {
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.2);

  img {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
}

.caption-style {
  position: relative;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: table;
}

.caption-style .icon {
  opacity: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 5;
}

.caption-style:hover .caption,
.caption-style:hover .icon {
  opacity: 1;
}

.caption-style:hover img {
  opacity: 1;
  transform: scale(1.15, 1.15);

  &.rotate_images {
    &__position_0 {
      transform: rotate(0) scale(1) scale(1.15, 1.15);
    }

    &__position_1 {
      transform: rotate(90deg) scale(0.75) scale(1.15, 1.15);
    }

    &__position_2 {
      transform: rotate(180deg) scale(1) scale(1.15, 1.15);
    }

    &__position_3 {
      transform: rotate(270deg) scale(0.75) scale(1.15, 1.15);
    }
  }
}

.caption-style img {
  margin: 0px;
  padding: 0px;
  float: left;
  z-index: 0;
}

.caption-style .caption{
  cursor: pointer;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  -webkit-transition:all 0.45s ease-in-out;
  -moz-transition:all 0.45s ease-in-out;
  -o-transition:all 0.45s ease-in-out;
  -ms-transition:all 0.45s ease-in-out;
  transition:all 0.45s ease-in-out;
  top: 0;
  z-index: 0;
}

.caption-style img {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.caption-style .blur {
  background-color: rgba(0,0,0,0.65);
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  max-height: 1000px;
}

.caption-style .caption-text h1{
  text-transform: uppercase;
  font-size: 24px;
  line-height: 25px;
  font-weight: 700;
  color: #fff !important;
  margin-bottom: 20px;
}
.caption-style .caption-text{
  z-index: 10;
  color: #fff;
  width: 80%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.section-lg.section-lg-grid {
  padding-bottom: 0;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.wrapper-menu {
  flex: 1;
  position: relative;
  .inner-wrapper-menu {
    text-align: center;
  }
  .v-btn {
    margin-bottom: 20px;
  }

  .v-btn:not(:last-child) {
    margin-right: 10px;
  }
}

.switch-menu {
  position: absolute;
  top: -66px;
  right: 0;
  width: 150px;
  text-align: center;
}

.wrapper-categories {
  padding: 20px;
  width: 100vw;
  max-width: 600px;
  strong {
    font-size: 12px;
    padding: 17px 30px 9px;
    position: relative;
    top: -17px;
    border-bottom: 1px solid;
    display: block;
    text-align: center;
  }
}

.flag-container {
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
	top: -5px;
	left: -5px;
  z-index: 1;

  .flag {
    width: 164px;
    padding: 7px;
    background-color: var(--v-primary-base);
    color: #fff;
    text-align: center;
    transform: rotate(-45deg) translate(-28%, -35%);
    box-shadow: 0px 5px 5px rgba(0,0,0,0.4);
    text-transform: uppercase;
    text-shadow: 0 2px 2px rgba(0,0,0,0.4);
  }
}

.list-menu {
  margin: 0 0px !important;
  padding: 0 !important;
  justify-content: center !important;
  display: flex !important;
  li {
    text-align: left;
    list-style: none;
    cursor: pointer;
    position: relative;
    margin: 10px 0;
    ul {
      display: none;
      position: absolute;
      left: 0;
      width: auto;
      max-width: 300px;
      background: #fff;
      box-shadow: 0 4px 8px -4px rgb(94 86 105 / 42%);
      padding: 0;
      border-radius: 6px;
      top: 70%;
      z-index: 5;

      color:rgba(94, 86, 105, 0.87);
      text-transform: uppercase;
      font-size: 0.875rem;
      font-family: Inter, sans-serif;
      font-weight: 500;

      li {
        padding: 0 20px 0 15px;
        display: block;
        min-width: 150px;
        span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .arrow-icon {
        transform: rotate(-90deg);
        position: absolute;
        right: -2px;
      }

      ul {
        left: 100%;
        top: -10px;
      }
    }
    &:hover {
      text-decoration: underline;
      > ul {
        display: block;
      }
    }
  }
  > li {
    float: left;
    margin: 0;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.wrapper-buttons {
  position: relative;
  height: 50px;
}

.switch-slider-view {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
